import * as React from 'react';

const HomePage = () => {
    return (
        <div>
            Home
            <div>
                About me
            </div>
            <div>
                Projects
            </div>
        </div>
    );
};

export default HomePage;